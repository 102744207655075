<template lang="pug">
  v-card
    v-card-title
      h2.card-title {{ 'modals.suppliers.create.title' | translate }}
    v-card-content
      v-text-field.mb-3(
        :label="$t('base.first_name')"
        v-model="form.name")
      v-select.mb-3(
        :label="$t('base.type')"
        item-value="key"
        :options="options"
        v-model="form.type")
      v-text-field(
        :label="$t('base.info')"
        v-model="form.info")
    v-card-actions
      v-btn(
        :disabled="!isNameValid || !isTypeSelected"
        :loading="loading"
        @click="onCreate")
        | {{ 'actions.create' | translate }}
</template>

<script>
export default {
  name: 'CreateNewModal',

  data: () => ({
    form: {
      name: '',
      type: null,
      info: ''
    },
    options: [
      { name: 'constants.provider_types.buyer', key: 'buyer' },
      { name: 'constants.provider_types.provider', key: 'provider' }
    ],
    loading: false
  }),

  computed: {
    isNameValid () {
      return !!this.form.name
    },

    isTypeSelected () {
      return this.form.type !== null
    }
  },

  methods: {
    async onCreate () {
      this.loading = true
      const payload = {
        name: this.form.name,
        type: this.form.type,
        info: this.form.info
      }
      const { data, error } = await this.$store.dispatch('addProvider', payload)
      this.loading = false
      if (error) {
        for (const key in data) {
          for (const msg of data[key]) {
            this.$toasted.show(this.$t(`errors.${msg}`), {
              position: 'bottom-center',
              duration: 5000
            })
          }
        }
      } else {
        this.$emit('close')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
