<template lang="pug">
  div
    .sidebar-layout__toolbar
      //- button.btn.btn--brand.mr-2(
        :class="{ 'btn--disabled': false }"
        @click="modals.showFilters = true")
        font-awesome-icon.mr-1(
          size="sm"
          icon="filter")
        span Додати фільтр

      v-dialog(
        max-width="350"
        v-model="modals.showFilters"
      )
        filters-modal(
          @close="modals.showFilters = false")

      button.btn.btn--brand(
        v-permission-hide="permissions.create_provider"
        @click="modals.showCreateNew = true")
        font-awesome-icon.mr-1(
          size="sm"
          icon="plus")
        span {{ isMobile ? 'Додати' : 'actions.add_provider' | translate }}

      v-dialog(
        max-width="350"
        v-model="modals.showCreateNew")
        create-new-modal(
          @close="modals.showCreateNew = false")

    navigation
    //- FOR DESKTOP AND TABLETS
    template(v-if="!isMobile")
      suppliers-table

    //- FOR MOBILE ONLY
    template(v-if="isMobile")
      suppliers-table-mobile

</template>

<script>
import adaptationMixin from '@/mixins/adaptation.mixin'

import Navigation from '../common/Navigation'

import FiltersModal from './modals/FiltersModal'
import CreateNewModal from './modals/CreateNewModal'
import permissions from '@/util/permissions'

const SuppliersTable = () => import('./SuppliersTable')
const SuppliersTableMobile = () => import('./SuppliersTableMobile')

export default {
  name: 'StorageSuppliers',

  mixins: [adaptationMixin],

  components: {
    Navigation,
    SuppliersTable,
    SuppliersTableMobile,
    FiltersModal,
    CreateNewModal
  },

  data: () => ({
    modals: {
      showFilters: false,
      showCreateNew: false
    },
    permissions: permissions
  })
}
</script>

<style lang="scss" scoped>
</style>
